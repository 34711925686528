<template>
  <div>
    <!-- -->
    <b-row>
      <b-col>
        <div style="margin-top:39px">
          <p>
            This is the first email the survey respondants will receive after
            submitting their emails. Click on the preview button to view the
            emailer.
          </p>
        </div>
      </b-col>
    </b-row>
    <div style="height:52px;"></div>
    <b-row>
      <b-col>
        <!--- --->
        <b-table-simple
          v-if="opticianEmailData && opticianEmailData.length > 0"
          id="table-1"
          hover
          small
          caption-top
          responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th
                v-for="data in header"
                :key="data.title"
                :class="isEditContent"
                :style="data.style"
              >
                {{ data.title }}
                <p v-if="data.desc" class="mb-0 th-desc">
                  {{ data.desc }}
                </p>
              </b-th>
            </b-tr>
          </b-thead>
          <!---- body --------------------------------------------- -->
          <b-tbody>
            <template v-for="(optician, pos) in opticianEmailData">
              <!---other dataa --->
              <b-tr
                v-for="(content, index) in optician.content"
                :key="content.id"
                :class="pos !== 3 ? '' : 'd-none' "
              >
                <b-td>
                  <h5 class="color">
                    {{ optician.section[index].data }}
                  </h5>
                </b-td>
                <!------>
                <b-td>
                  <div v-if="content.data != ''">
                    <!-- Not edit in Visual Profile Header section-->
                    <Editor v-if="pos === 8"
                            v-model="content.data" 
                            :type="'a'"
                            :is-edit="content.isEdit" 
                            :num-index="index"
                            :num-idx="pos"
                    />
                    <Editor v-else
                            v-model="content.data" 
                            :is-edit="content.isEdit" 
                            :num-index="index"
                            :num-idx="pos"
                    />
                  </div>
                </b-td>
                <!------>
                <b-td v-if="pos === 0">
                  <div v-if="optician.otherInformation[index].data != ''">
                    <Editor
                      v-model="optician.otherInformation[index].data"
                      :type="'a'"
                      :is-edit="optician.otherInformation[index].isEdit"
                      :num-index="index"
                      :num-idx="pos"
                    />
                  </div>
                </b-td>
                
                <b-td v-else-if="pos === 1">
                  <FileUpload
                    v-model="optician.otherInformation[index].data"
                    :is-edit="optician.otherInformation[index].isEdit"
                  />
                </b-td>
                <b-td v-else-if="optician.section[0].data === 'Links'">
                  <Editor
                    v-model="optician.otherInformation[index].data"
                    :type="'email'"
                    :is-edit="optician.otherInformation[index].isEdit"
                    :num-index="index"
                    :num-idx="pos"
                  />
                </b-td>
                <b-td v-else>
                  <div v-if="optician.otherInformation[index].data != ''">
                    <Editor
                      v-model="optician.otherInformation[index].data"
                      :num-index="index"
                      :num-idx="pos"
                      :is-edit="optician.otherInformation[index].isEdit"
                    />
                  </div>
                </b-td>
                <!------>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fixHeaderTable, randomData } from "../../common/CommonUtils";
import Editor from "./Editor.vue";
import FileUpload from "./FileUpload.vue";

const HEADERS_TABLE = [
  { title: "SECTION", style: "width: 20%", class: "", desc: "" },
  { title: "BUTTON", style: "width: 40%", class: "", desc: "" },
  { title: "OTHER INFORMATION", style: "width: 40%", class: "", desc: ".jpeg or .png files only. Max 1MB. Dimension 1002 X 900px" },
];

export default {
  name: "FindAnOpticianEmail",
  components: {
    Editor,
    FileUpload,
  },
  data() {
    return {
      header: HEADERS_TABLE,
      curStyle: "color: black",
    };
  },
  computed: {
    opticianEmailData: {
      // using reference to store because vue can not detect change if data do not have key
      get: function() {
        let data = this.$store.state.email.opticianEmail.opticianData;
        const _this = this;
        data = data.map((otherData) => {
          const otherDataClone = otherData;
          otherDataClone.content = otherData.content.map((v) => {
            v["id"] = _this.random();
            return v;
          });
          return otherDataClone;
        });
        console.log(data);
        return data;
      },
    },
    isEditContent() {
      return this.$store.state.email.opticianEmail.editStatus
        ? "edit-th-content"
        : "normal-th-content";
    },
  },
  updated() {
    fixHeaderTable(
      "table-1",
      "header-nav",
      this.$store.state.email.opticianEmail.editStatus
    );
  },
  methods: {
    random() {
      return randomData(5, 2);
    },
  },
};
</script>

<style scoped>
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
}

.otherData-td {
  border-top: none;
}

.color {
  color: #006db6;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
}
.th-desc {
  font-weight: 300;
}
td {
  word-break: break-word;
}
</style>
